import { FC } from 'react'
import { SectionTypeCode, sectionTypes } from '@lifedot/constants/searchChoices'
import { ErrorMessage } from '@/components/Input/ErrorMessage'
import { RequiredLabel } from '@/components/RequiredLabel'
import { useFormContext } from 'react-hook-form'
import * as Yup from 'yup'
import { Checkbox } from '@/components/Input/Checkbox'
import { FormItemFrame } from '@/components/FormItemFrame'
import { css } from '@emotion/react'
import Image from 'next/image'
import { typography } from '@lifedot/styles/typography'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { EventTracker } from '@lifedot/tracking'
import { SupplementText } from '@/components/SupplementText'

const styles = {
  note: css({
    display: 'inline-block'
  }),
  layout: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 8,
    [mq('sp')]: {
      gridTemplateColumns: '1fr'
    }
  }),
  label: css({
    display: 'grid',
    gridTemplateColumns: '24px 74px 1fr',
    alignItems: 'center',
    gap: 8,
    border: `2px solid ${palette.gray4}`,
    borderRadius: 4,
    padding: 8,
    cursor: 'pointer'
  }),
  checked: css({
    backgroundColor: 'rgba(112, 186, 44, 0.1)',
    fontWeight: 'bold'
  })
}

export const sectionTypesSchema = {
  section_types: Yup.array(Yup.string().required())
    .required('お墓タイプをいずれか1つ選択してください')
    .min(1, 'お墓タイプをいずれか1つ選択してください')
    .nullable()
}

const yupObject = Yup.object(sectionTypesSchema)
type SectionTypesFormValues = Yup.InferType<typeof yupObject>

type SectionTypesProps = {
  showNote?: boolean
}

export const SectionTypes: FC<SectionTypesProps> = ({ showNote }) => {
  const { watch } = useFormContext<SectionTypesFormValues>()
  const values = watch('section_types') || []
  const allInputted = sectionTypesSchema.section_types.isValidSync(values)
  return (
    <FormItemFrame
      title={
        <>
          <p css={typography.textL}>
            <b>ご検討中のお墓タイプ</b>
            <small css={styles.note}>(複数選択可)</small>
          </p>
          <RequiredLabel allInputted={allInputted} />
        </>
      }
    >
      <AddMarginWrapper spacing={2}>
        <div css={styles.layout}>
          {sectionTypes.map((sectionType) => (
            <label
              css={[
                styles.label,
                values.includes(sectionType.code) && styles.checked
              ]}
              key={sectionType.code}
            >
              <EventTracker
                label={`sectionType_${sectionType.code}`}
                action="click"
                elementLabel={`section_type_${sectionType.code}`}
                clone
              >
                <Checkbox<SectionTypesFormValues>
                  name="section_types"
                  value={sectionType.code}
                />
              </EventTracker>
              <Image
                src={sectionTypeImages[sectionType.code] ?? ''}
                width={74}
                height={52}
                alt=""
              />
              <span>
                {sectionType.name}
                <br />
                {sectionType.code === SectionTypeCode.NORMAL && (
                  <small>（永代供養付きプラン含む）</small>
                )}
              </span>
            </label>
          ))}
        </div>
        <ErrorMessage<SectionTypesFormValues> name="section_types" />
        {showNote && (
          <SupplementText text="お問い合わせいただく霊園が、ご希望のお墓タイプを受け付けていない場合がございます。" />
        )}
      </AddMarginWrapper>
    </FormItemFrame>
  )
}

const sectionTypeImages: Record<string, string> = {
  normal: 'https://s3.lifedot.jp/uploads/ending/path/37664/ippanbo.png',
  jumoku: 'https://s3.lifedot.jp/uploads/ending/path/7915/jumokuso.png',
  nokotsu:
    'https://s3.lifedot.jp/uploads/ending/path/7914/nokotsudo-locker.png',
  eitai_kuyo: 'https://s3.lifedot.jp/uploads/ending/path/37665/eitaikuyoubo.png'
}
