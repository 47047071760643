import { FC } from 'react'
import { css } from '@emotion/react'
import Image from 'next/image'
import Link from 'next/link'
import { mq } from '@lifedot/styles/mediaQuery'
import { lifedotDirectNumber } from '@lifedot/constants/phoneNumbers'
import { EventTracker } from '@lifedot/tracking'

const styles = {
  sp: css({
    display: 'none',
    [mq('sp')]: {
      display: 'block'
    }
  }),
  pc: css({
    [mq('sp')]: {
      display: 'none'
    }
  })
}

export const Banner: FC = () => {
  return (
    <>
      <div css={styles.pc}>
        <Image
          src="https://s3.lifedot.jp/uploads/ending/path/79141/banner_form-pc.png"
          width={1920}
          height={288}
          alt={`問い合わせはお電話でも受け付けております。電話番号${lifedotDirectNumber}`}
          priority
        />
      </div>
      <div css={styles.sp}>
        <EventTracker
          action="click"
          label="telLinkBanner"
          elementLabel="tel_link_banner"
        >
          <Link href={`tel:${lifedotDirectNumber}`} passHref>
            <a>
              <Image
                src="https://s3.lifedot.jp/uploads/ending/path/79140/banner_form-sp.png"
                width={750}
                height={232}
                alt={`問い合わせはお電話でも受け付けております。電話番号${lifedotDirectNumber}`}
                priority
              />
            </a>
          </Link>
        </EventTracker>
      </div>
    </>
  )
}
